.dash-board-card {
  @apply w-full font-semibold aspect-[1/.7] px-3 py-2 rounded-lg custom-shadow mx-auto relative;
}

.dash-board-card:nth-child(2n) {
  @apply bg-primary-200 dark:bg-primary-800;
}
.dash-board-card:nth-child(2n + 1) {
  @apply bg-primary-300 dark:bg-primary-800;
}
