.custom-input {
  @apply h-10 text-sm border border-primary-300 dark:border-primary-600 rounded-lg;
}

.custom-text-input {
  @apply border rounded-lg text-base border-primary-400 dark:border-primary-600 text-primary-700 dark:text-primary-100;
}

.btnClass {
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-normal {
  @apply bg-secondary-300 text-primary-800 dark:bg-primary-600 dark:text-primary-100 rounded-sm opacity-100 hover:opacity-80 transition-all duration-75;
}
.btn-success {
  @apply bg-secondary-700 text-primary-50 rounded-sm opacity-100 hover:opacity-80 transition-all duration-75;
}

.btn-danger {
  @apply bg-[red] text-primary-50 rounded-sm opacity-100 hover:opacity-80 transition-all duration-75;
}
.btn-warning {
  @apply bg-[#f67070] text-primary-50 rounded-sm hover:bg-opacity-75;
}
