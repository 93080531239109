@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-CustomFont;
  }
  h5 {
    @apply font-normal;
  }

  h4 {
    @apply text-xs font-medium;
  }

  h3 {
    @apply text-sm font-semibold;
  }

  h2 {
    @apply text-base font-bold;
  }
}
@layer components {
  .mini-card {
    width: 60px;
    height: 24px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    filter: drop-shadow(0px 3px 3.5px rgba(0, 0, 0, 0.16));
    display: flex;
    align-items: center;
    justify-content: center;
    @apply text-primary-700 bg-white;
  }
}
@layer utilities {
  .centerY {
    @apply flex items-center;
  }
  .centerX {
    @apply flex justify-center;
  }
  .centerXY {
    @apply flex justify-center items-center;
  }
  .custom-shadow {
    /* box-shadow:  4px 4px 4px rgba(0, 0, 0, 0.15); */
    @apply shadow-light dark:shadow-dark;
  }
  .custom-border {
    @apply border border-[#dcdcdc] dark:border-[#464646] rounded-lg;
  }
  .stroke-path {
    @apply [&>path]:stroke-black dark:[&>path]:stroke-white;
  }
  .fill-path {
    @apply [&>path]:fill-black dark:[&>path]:fill-white;
  }
  .position-center {
    @apply absolute left-[50%] top-[50%] transform -translate-x-1/2 -translate-y-1/2;
  }
  .custom-background {
    @apply custom-shadow rounded-lg p-4 bg-primary-50 text-primary-800 dark:text-primary-50 dark:bg-primary-800;
  }
  .custom-scroll::-webkit-scrollbar {
    width: 4px;
  }
  .custom-scroll {
    overflow: auto;
  }
  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(97, 135, 97, 0.084);
    border-radius: 16px;
  }
  .custom-scroll::-webkit-scrollbar-track {
    background-color: inherit;
  }
  .form-wrapper {
    @apply max-w-lg p-6 bg-primary-50 dark:bg-primary-800 mx-auto rounded-lg;
  }
  .btn-delete {
    @apply rounded-[4px] transition-all duration-100 cursor-pointer border border-[#dc3545] hover:bg-[#dc3545] hover:text-white flex items-center justify-center;
  }
  .btn-edit {
    @apply rounded-[4px] transition-all duration-100 cursor-pointer border border-[#28a745] hover:bg-[#28a745] hover:text-white flex items-center justify-center;
  }
  .bg-inherit {
    background-color: inherit;
  }
  .pagination-wrapper {
    @apply my-4 overflow-x-auto;
  }

  .table-wrapper {
    @apply whitespace-nowrap max-h-[450px] overflow-auto;
  }
  .table-wrapper table {
    @apply min-w-fit w-full table-auto text-sm text-center;
  }
  .table-wrapper thead > tr {
    @apply z-10 sticky top-0 bg-primary-300 dark:bg-primary-600 uppercase leading-normal h-10;
  }

  .table-wrapper tbody tr:nth-child(2n + 1) {
    @apply border border-x-0 border-primary-300 dark:border-primary-600 bg-primary-50 dark:bg-primary-800;
  }
  .table-wrapper tbody tr:nth-child(2n) {
    @apply border border-x-0 border-primary-300 dark:border-primary-700 bg-primary-100 dark:bg-[#15191f];
  }
  .table-wrapper th,
  td {
    @apply p-1 h-10 max-w-[160px] whitespace-normal break-words;
  }

  .inline-input {
    background: inherit;
    padding: 2px;
    max-width: 110px;
  }
  .inline-input:focus {
    outline: none;
  }

  .equal-row-height [class*='col-span-'] > div {
    height: 100%;
  }
}
