.searched-box {
  position: absolute;
  top: calc(100% - 6px);
  width: calc(100% - 16px);
  z-index: 99;
  max-height: 270px;
  overflow-y: auto;
  min-height: 100px;
  left: 50%;
  transform: translateX(-50%);
}
