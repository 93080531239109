.customTable tr {
  background: none !important;
  border: 0 !important;
  height: 2rem;
}
.customTable td {
  padding: 0;
}
.custom-calender {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
