.layout .main-section {
  @apply min-h-screen bg-primary-400 dark:bg-primary-900 w-full lg:flex text-primary-900 dark:text-primary-50;
}

.layout .main-section .left-part {
  width: 250px;
  transition: margin-left 0.4s, opacity 0.3s ease-in-out;

  @apply z-20 bg-primary-400 dark:bg-primary-900;
}
.layout .main-section .right-part {
  transition: margin-left 0.4s ease-in-out;
  @apply z-10 p-4;
}
