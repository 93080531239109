.select-from-option {
  width: 100%;
  height: fit-content;
  position: relative;
  background-color: inherit;
}
.select-from-option input {
  background-color: inherit;
  outline: none;
  font-size: 14px;
}

.select-from-option input::placeholder {
  font-size: 14px;
  opacity: 0.7;
}
.select-from-option .listContainer {
  position: absolute;
  top: calc(100% - 6px);
  width: 100%;
  z-index: 99;
  max-height: 270px;
  overflow-y: auto;
  min-height: 100px;
}

.header-class {
  @apply border h-10 rounded-lg text-sm border-primary-300 dark:border-primary-700 text-primary-800 bg-primary-100 dark:bg-primary-900 dark:text-primary-100;
}
.content-class {
  @apply shadow-lg text-sm text-primary-700 dark:text-primary-100 bg-primary-300 dark:bg-primary-800;
}
