.navbar {
  @apply flex items-center flex-row px-2 h-[70px] sticky top-0 lg:relative z-50 text-primary-800 dark:text-primary-50 bg-primary-50 dark:bg-primary-800 drop-shadow-md;
}

.navbar .custom-search {
  height: 38px;
  border-radius: 5px;
  @apply flex items-center flex-row-reverse min-w-[80px] sm:w-72 bg-primary-100 dark:bg-primary-700;
}

.navbar .custom-search input {
  padding: 0;
  padding-right: 4px;
}
.navbar .icon svg > path:not(:first-child) {
  @apply fill-black dark:fill-white;
}
.navbar .logoutIcon > path {
  @apply stroke-black dark:stroke-white;
}

.navbar svg {
  height: auto;
}
