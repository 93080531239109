.search {
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.search input {
  width: 100%;
  height: 100%;
  padding: 0 8px;
  color: inherit;
  background: inherit;
  background-color: inherit;
}
.search input::placeholder {
  font-size: 14px;
  opacity: 0.5;
  color: inherit;
}
.search input:focus-visible {
  outline: none !important;
  outline-offset: 0px;
}
