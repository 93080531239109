.render-tab .labels-wrapper {
  display: flex;
  align-items: center;
  height: 36px;
  position: relative;
  margin-top: -10px;
  margin-bottom: 16px;
  @apply border-b border-solid border-primary-300 dark:border-primary-800;
}
.render-tab .labels-wrapper .label {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0px 16px;
  cursor: pointer;
}
.render-tab .labels-wrapper .active-class {
  border-bottom: 2px solid;
  margin-top: 2px;
  @apply text-secondary-600;
}
.sub-tab-body .expand-body {
  display: none;
  margin-left: 24px;
  height: 0;
}

.sub-tab-body .expand + .expand-body {
  display: block;
  height: auto;
}
.render-tab svg {
  width: 100%;
  height: auto;
}
