.paginationButtons {
  width: 80%;
  display: flex;
  list-style: none;
  justify-content: flex-end;
}
.paginationButtons a {
  cursor: pointer;
  margin: 3px;
  padding: 5px;
  min-width: 35px !important;
  display: block;
  text-align: center !important;
  border-radius: 4px;
  @apply bg-primary-300 dark:bg-primary-800;
}

.activePaginate a {
  @apply bg-primary-500 dark:bg-primary-600;
}

.pagination-container {
}
